function setEqualHeight() {
    $(".grid-news-item .news-content-wrapper").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});
